import React from 'react';

import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';
import type { ReactNode } from 'react';

import { ThemeProvider } from '@components/ThemeProvider';
import { Breakpoints } from '@cvent/carina/components/Breakpoints';
import { GlobalStyles } from '@components/GlobalStyles';
import { LocalizationProvider } from 'nucleus-text';
import { useQuery } from '@apollo/client';
import { GetLocaleDocument } from '@cvent/ska-app-model/operations';
import locales from '../../locales';

export default function AppProviders({ children }: { children: ReactNode }): JSX.Element {
  const { data } = useQuery(GetLocaleDocument);
  const { locale } = data;

  return (
    <ThemeProvider>
      <LocalizationProvider locale={locale} clientSideLocales={locales} serverSideLocales={locales}>
        <GlobalStyles />
        <Breakpoints ssrSize="m" key={typeof window === 'undefined' ? 'ssr' : 'csr'}>
          {children}
        </Breakpoints>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
